import {useState, useEffect} from 'react'
import WhitelistCard from './WhitelistCard';
import { ethers } from "ethers";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useContractRead,
  usePrepareContractWrite,
} from "wagmi";
import WhitelistABI from './../../abi/on-chain-whitelist-with-get-all-abi.json'
import { mainnet, polygon, goerli, polygonMumbai } from 'wagmi/chains'
import { useIsMounted } from "./../../hooks/useIsMounted";
import { whitelistAddress, BACKEND_ADDR } from './../Config';

export default function WhitelistContainer() {
  const mounted = useIsMounted();
  const { address, isConnected } = useAccount();
  const [whitelists, setWhitelists] = useState([])

  const { data: whitelistData } = useContractRead({
      address: whitelistAddress,
      abi: WhitelistABI,
      functionName: "getAllWhitelists",
      chainId: 80001,
      watch: true,
      });
  useEffect(() => {
    if (whitelistData) {
      setWhitelists(whitelistData);
    }
  }, [whitelistData]);
  // console.log(whitelistData)

  return (<>
    {mounted ? isConnected && <div className="bg-octo-red inner-shadow-10">
        <div className="p-10 mb-10 outer-shadow-14">
            <h2 className="text-xl text-center text-octo-green">Market</h2>
        </div>
        <div className="whitelist-container">
        {whitelists.map((whitelist, index) => (
            <WhitelistCard
                key={index }
                id={index + 1}
                allPurchases={whitelist.allPurchases}
                endTime={whitelist.endTime}
                image={whitelist.image}
                isBurn={whitelist.isBurn}
                name={whitelist.name}
                price={whitelist.price}
                slots={whitelist.slots}
                startTime={whitelist.startTime}
                whitelistAddress={whitelistAddress}
                BACKEND_ADDR={BACKEND_ADDR}
             />
        ))}
        </div>
    </div> : null}
    </>

  )
}

