import {useEffect, useState} from 'react'
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import {useAccount,useConnect,useDisconnect,useEnsName,useContractRead, useNetwork} from "wagmi";
import { mainnet, polygon, goerli, polygonMumbai } from 'wagmi/chains'
import { InjectedConnector } from 'wagmi/connectors/injected'
import whitelistABI from '../../abi/on-chain-whitelist-with-get-all-abi.json'
import { useIsMounted } from '../../hooks/useIsMounted';
import OctoLogo from "../../images/octo-logo.png"
import truncateEthAddress from "truncate-eth-address";
import {Navbar, MobileNav,li,Button,IconButton} from "@material-tailwind/react";
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';
import PinkzBalanceCheck from './../balances/PinkzBalanceCheck';
import { whitelistAddress, BACKEND_ADDR} from './../Config';
import { signOut, getInkzBalance, getPinkzBalance } from './../../lib/lib';

const domain = window.location.host;
const origin = window.location.origin;
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();


export default function Header() {
  const mounted = useIsMounted();
  const [openNav, setOpenNav] = useState(false);
  const { address, isConnected, isConnecting } = useAccount()
  //const { data: ensName } = useEnsName({ address })
  const { connect } = useConnect({connector: new InjectedConnector(),
    onSettled(data, error) {
      console.log('Settled', { data, error })
      signInWithEthereum()
    },})
  const { disconnect } = useDisconnect({
      onSettled(data, error) {
      setAuthenticated(false)
      setInkzBalance("")
      console.log('Settled', { data, error })
      signOut()
    },
  })
  const { chain, chains } = useNetwork()
  const [inkzBalance, setInkzBalance] = useState("")
  const [pinkzBalance, setPinkzBalance] = useState("")
  const [authenticated, setAuthenticated] = useState(false)

  const { data: authorizedData } = useContractRead({
    address: whitelistAddress,
    abi: whitelistABI,
    functionName: "authorized",
    enabled: isConnected && chain?.id === polygonMumbai.id,
    watch: false,
    args: [address],
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  useEffect(() => {
    getInformation()
  }, [])
  useEffect(() => {
    if(authenticated) {
      const fetchBalances = async () => {
        const balInkz = await getInkzBalance()
        const balPinkz = await getPinkzBalance()
        setInkzBalance(balInkz)
        setPinkzBalance(balPinkz)
      }
      fetchBalances()
    const interval=setInterval(()=>{
      fetchBalances()
      },10000)
  
  
    return()=>clearInterval(interval)
    }
  }, [authenticated])
    
  
  async function createSiweMessage(address, statement) {
    const res = await fetch(`${BACKEND_ADDR}/nonce`, {
        credentials: 'include',
    });
    const message = new SiweMessage({
        domain,
        address,
        statement,
        uri: origin,
        version: '1',
        chainId: polygonMumbai?.id,
        nonce: await res.text()
    });
    return message.prepareMessage();
  }

  async function signInWithEthereum() {
    const message = await createSiweMessage(
        await signer.getAddress(),
        'Sign into OctoHedz Marketplace.'
    );
    const signature = await signer.signMessage(message);

    const res = await fetch(`${BACKEND_ADDR}/verify`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, signature }),
        credentials: 'include'
    });
    if(res.status == 200){
      setAuthenticated(true);
      return
    }
  }
    

  async function getInformation() {
    if(isConnected) {
      const res = await fetch(`${BACKEND_ADDR}/personal_information`, {
        credentials: 'include',
    });
    const statusCode = await res.status
    if(statusCode !== 401) {
      setAuthenticated(true)
    }
    }
  }

  

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <li>
        <NavLink to="/" className={({isActive}) => {
          return ('flex items-center ' + (isActive ? 'text-octo-brightred' : null))
        }} >
        <p className="text-sm">Home</p>
        </NavLink>
      </li>
      <li>
        <NavLink to="/market" className={({isActive}) => {
          return ('flex items-center ' + (isActive ? 'text-octo-brightred' : null))
        }} >
          <p className="text-sm">Market</p>
        </NavLink>
      </li>
      <li>
        <NavLink to="/bridge" className={({isActive}) => {
          return ('flex items-center ' + (isActive ? 'text-octo-brightred' : null))
        }} >
        <p className="text-sm">Bridge</p>
        </NavLink>
      </li>
      {isConnected && authorizedData &&<li
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <NavLink to="/admin" className={({isActive}) => {
          return ('flex items-center ' + (isActive ? 'text-octo-brightred' : null))
        }} >
        <p className="text-sm">Admin</p>
        </NavLink>
      </li>}
    </ul>
  )
  return (<>{mounted ? (
    <div className="sticky-nav w-full">
    <Navbar className="bg-octo-blue text-octo-green mx-auto drop-shadow-xl border-gray-800 max-w-screen-xxl py-2 px-4 lg:px-8 lg:py-4">
    <div className="container mx-auto flex items-center justify-between">
      <div className="flex items-center">
        <NavLink to="/" >
        <img src={OctoLogo} alt="logo" className="w-10 cursor-pointer "/>
        </NavLink>
        <div className="flex flex-col">
        {mounted ? isConnected && authenticated && <PinkzBalanceCheck inkzBalance={inkzBalance} pinkzBalance={pinkzBalance} /> : null}
        </div>
      </div>
      <div className="hidden lg:block">{navList}</div>
      {isConnected ? (
      <>
      <button onClick={(e) => {
                  e.preventDefault()
                  disconnect()
                }} className="bg-octo-green px-4 py-4 text-octo-blue mx-2">
        <p className="text-xs">{truncateEthAddress(address)}</p>
      </button>
      </>) : (<><button onClick={(e) => {
                  e.preventDefault()
                  connect()
                }} className="bg-octo-green px-4 py-4 text-octo-blue mx-2"><p className="text-xs">connect</p></button></>)}
      
      <IconButton
        variant="text"
        className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
        ripple={false}
        onClick={() => setOpenNav(!openNav)}
      >
        {openNav ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="h-6 w-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </IconButton>
    </div>
    <MobileNav open={openNav}>
      {navList}
    </MobileNav>
  </Navbar>
  </div>
  ): null}</>
    
  )
}
