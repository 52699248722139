import {useRef, useState} from 'react'
import ReactDom from 'react-dom'
import { HiMenu } from "react-icons/hi";

export default function RevealModal({dataServed}) {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(!showModal)
    }
  return (
    <>
    {showModal ? 
        <div className="bg-octo-blue modal text-octo-green shadow-xl text-center">
            <p className="small-text text-octo-brightred pb-4 mt-4 cursor-pointer" onClick={openModal}>close</p>
            <p className="pb-2 mx-20 px-5 text-sm">Revealed Message</p>
            <p className="small-text pb-2">{dataServed}</p>
        </div> 
        : <div className="purchased-item cursor-pointer">
        <p className="text-xl text-center mt-3" onClick={openModal}>Reveal...</p>
      </div>}
    </>
  )
}