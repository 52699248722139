import { useState } from "react";
import InkzLogo from "./../../images/inkz.gif";
import PinkzLogo from "./../../images/pinkz.gif";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
} from "wagmi";
import { mainnet, polygon, goerli, polygonMumbai } from "wagmi/chains";
import { ethers } from "ethers";
import Spinner from './../spinners/Spinner';
import BridgeABI from './../../abi/on-chain-whitelist-with-get-all-abi.json'
import BridgeImg from "./../../images/bridgefull.gif";
import { bridgeAddress } from './../Config';

export default function BridgeContainer() {
  const { address } = useAccount();
  const { chain, chains } = useNetwork();
  const [amount, setAmount] = useState(30);
  const [submitBtn, setSubmitBtn] = useState("Bridge");

  const onCorrectChain = chain.name === goerli.name;
  //console.log(onCorrectChain)

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    address: bridgeAddress,
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "_user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
          },
        ],
        name: "deposit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "deposit",
    chainId: goerli.id,
    enabled: onCorrectChain,
    args: [address, (amount * 1000000000000000000).toString()],
  });

  const {
    data,
    error: writeError,
    isError,
    isLoading: isBridgeLoading,
    isSuccess: isBridgeStarted,
    write,
  } = useContractWrite(config);

  const { isLoading, isSuccess: txSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  const isBridged = txSuccess;

  return (
    <>
      <div className="bridge-box flex mb-10">
        <div>
          <h1 className="text-octo-green text-3xl">BRIDGE</h1>
          <div className="flex items-center justify-between">
            <h1 className="text-2xl text-octo-brightred">INKz</h1>
            <h1 className="text-2xl">to</h1>
            <h1 className="text-2xl text-octo-pink">PINKz</h1>
          </div>
          <div className="text ">
            <div className="flex justify-between items-center">
              <img className="w-12" src={InkzLogo} alt="inkz" />
              <div className="flex items-center justify-center space-x-2">
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-brightred"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-brightred"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-brightred"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-brightred"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-pink"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-pink"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-pink"></div>
                <div className="w-4 h-4 rounded-full animate-pulse bg-octo-pink"></div>
              </div>
              <img className="w-12 mr-4" src={PinkzLogo} alt="pinkz" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                write?.();
              }}
            >
              <div>
                <input
                  className="input-bridge-field"
                  placeholder="ENTER AMOUNT"
                  type="number"
                  min="30"
                  max="10000"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div>
                <button
                  disabled={
                    isBridgeLoading || isBridgeStarted || !onCorrectChain
                  }
                  className="bridge-button text-base"
                >
                  {isBridgeLoading && (
                    <div className="flex items-center content-center justify-center">
                      <p className="text-base pr-2">Awaiting</p>
                      <Spinner />
                    </div>
                  )}
                  {isBridgeStarted && !isBridged && (
                    <div className="flex items-center content-center justify-center">
                      <p className="text-base pr-2">Confirming</p>
                      <Spinner />
                    </div>
                  )}
                  {isBridged && <p className="text-base">Successful!</p>}
                  {!isBridgeLoading && !isBridgeStarted && !isBridged && (
                    <p className="text-base">Bridge</p>
                  )}
                </button>
                {!onCorrectChain && (
                  <p className="text-base text-octo-green pt-2">
                    Switch to Mainnet
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="box-small text-center text-octo-green mx-4">
        <div className="border border-red-700 pt-4 mx-20">
          <h3 className="text-xl text-octo-brightred">Minimum bridge amount</h3>
          <h3 className="text-3xl pb-4">30 INKz</h3>
        </div>
        <p className="pt-4">
          Make sure you are connected to Etheruem Mainnet ETH before
          continuing.
        </p>
        <p>
          Bridging may take up to 15 minutes depending on current network
          conditions.
        </p>
      </div>
      <div className="box-small text-center text-octo-green mx-4 ">
        <p>Bridging is a one way process at the moment.</p>
        <p>
          If you need help, please contact a mod in our discord. They will be
          happy to help.
        </p>
      </div>
    </>
  );
}