// test deployment local config
// export const BACKEND_ADDR = "http://localhost:5000"
// export const pinkzAddress = "0x962F803Dc1bA8d645a217D0Bd16d4D8a63971adB"
// export const inkzAddress = "0xd0c37Cda48Fe5D6Ee7132181a90DC58206Db5404";
// export const bridgeAddress = "0xB935f8DcD93B081F58145172F65167963aF94f84"
// export const whitelistAddress = "0x8c4108Ae6b780aDb186d95F8EccFce8eA0Dfed47"
// export const auctionAddress = "0x17Bc2cd4dF2C5Cad25086af29B5F3bB3C3EeD170"

// test deployment online config
export const BACKEND_ADDR = "https://octohedzapi.yolokidz.io"
export const pinkzAddress = "0x962F803Dc1bA8d645a217D0Bd16d4D8a63971adB"
export const inkzAddress = "0xd0c37Cda48Fe5D6Ee7132181a90DC58206Db5404";
export const bridgeAddress = "0xB935f8DcD93B081F58145172F65167963aF94f84"
export const whitelistAddress = "0x8c4108Ae6b780aDb186d95F8EccFce8eA0Dfed47"
export const auctionAddress = "0x17Bc2cd4dF2C5Cad25086af29B5F3bB3C3EeD170"

// main deployment config
// const BACKEND_ADDR = "https://octohedzapi.yolokidz.io"
// export const pinkzAddress = ""
// export const inkzAddress = ""
// export const bridgeAddress = ""
// export const whitelistAddress = ""
// export const auctionAddress = ""